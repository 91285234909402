import React from 'react';

const DividerLine = () => {
  return <hr className="w-full border-grey-border" />;
};

const DividerContainer = ({ children }) => {
  return (
    <div className="h-1 flex justify-center bg-red">
      <div
        className={`h-8 -m-4 z-20 flex flex-col justify-center
        bg-red text-white px-8 text-sm font-semi-bold leading-none uppercase`}
      >
        {children}
      </div>
    </div>
  );
};

export { DividerLine, DividerContainer };
