import React from "react";
import PropTypes from 'prop-types';
import { CONSENT_CATEGORY_SosiaalisetMediat, useConsent } from "../../utils/cookie-consent";
import { KATSOMO_VIDEO_URL_FORMAT } from "../../utils/constants";
import { RoundedButton } from "../button";



/** Extension of iframe. Checks that cookie consent has been given */
const ExternalVideo = ({src, className, allow, allowFullScreen})=>{
    const hasConsent = useConsent(CONSENT_CATEGORY_SosiaalisetMediat);
    const srcInternal = src.includes(KATSOMO_VIDEO_URL_FORMAT);

    let showVideo = false;

    if(srcInternal)
        showVideo = true; // Internal (Katsomo) videos can be shown
    else
    {
        if(hasConsent)
            showVideo = true;
    }


    if(showVideo) return (
        <iframe
            className={className}
            src={src}
            allow={allow}
            allowFullScreen={allowFullScreen}
        />
    )

    return <ConsertRequiredFallback/>;
}

ExternalVideo.propTypes = {
    src             : PropTypes.string,
    className       : PropTypes.string,
    allow           : PropTypes.string,
    allowFullScreen : PropTypes.bool,
};


/** Consent required fallback */
const ConsertRequiredFallback = ()=>(
    <div className="flex h-full p-4 text-black border border-grey-lighter rounded-md bg-grey-lightest">
        <div className="m-auto">
            <p className="font-bold">Miksi näen tämän?</p>
            <p>Et ole antanut lupaa evästeille joita tarvitaan etsimäsi sisällön esittämiseen.</p>
            <p>Hyväksy <span className="font-bold">Sosiaalinen media</span>, jotta näet sisällön.</p>
            <br/>
            <RoundedButton onClick={()=>(window as any).Optanon.ToggleInfoDisplay()}>Evästeasetukset</RoundedButton>
        </div>
    </div>
);

export default ExternalVideo;