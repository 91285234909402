import React from 'react';
import Img from 'gatsby-image';
import { HeaderText2, BodyTextM, BodyTextS, InfoText } from './text';
import { PreviewLink } from './link';
import { BLACK, GREY } from '../utils/color-constants';

const BusinessCard = ({
  image,
  firstName,
  lastName,
  title,
  region,
  phoneNumber,
  email,
}) => {
  return (
    <div className="relative w-full max-w-390px h-140px grid grid-cols-3 bg-white">
      {image ? (
        <Img
          className="w-full"
          fluid={
            image.localFile
              ? image.localFile.childImageSharp.fluid
              : image.fluid
          }
          alt={image.title}
        />
      ) : null}
      <div className="col-span-2 mt-4 ml-4 flex flex-col">
        <BodyTextM color={BLACK} bold={true}>
          {`${firstName} ${lastName}`}
        </BodyTextM>
        <BodyTextS color={BLACK} className="mt-2">{title}</BodyTextS>

        <div className="absolute bottom-0 mb-4">
          <BodyTextS color={BLACK} className="mt-4">
            {phoneNumber}
          </BodyTextS>
          <BodyTextS color={BLACK} className="mt-2">
            {email}
          </BodyTextS>
        </div>
      </div>
    </div>
  );
};

const PreviewCardWrapper = ({ items }) => {
  return (
    <div className="mt-16 px-auto grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 col-gap-4 row-gap-4 lg:row-gap-24">
      {items.map((item, i) => {
        return (
          <PreviewLink
            key={`previewLink_${item.title}_${i}`}
            image={item.previewImage}
            tag={item.tag}
            title={item.title}
            description={item.previewText}
            linkTo={`${item.pathPrefix}${item.slug}`}
          />
        );
      })}
    </div>
  );
};

const BigCardWithImage = ({ image, title, body }) => {
  return (
    <div className="min-h-300px p-6 sm:p-0 flex flex-col sm:flex-row border border-grey-warm">
      {image ? (
        <Img
          fluid={
            image.localFile
              ? image.localFile.childImageSharp.fluid
              : image.fluid
          }
          className="w-full max-w-220px"
        />
      ) : null}

      <div className="mt-6 sm:my-10 sm:mx-6 max-w-590px xl:w-310px">
        <HeaderText2>{title}</HeaderText2>
        <BodyTextS className="mt-2 leading-loose">{body}</BodyTextS>
      </div>
    </div>
  );
};

export { BusinessCard, PreviewCardWrapper, BigCardWithImage };
