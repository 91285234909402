import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import createTranslate from '../utils/translator-provider';
import ReactMarkdown from 'react-markdown';
import closeIcon from '../images/close-icon.png';

export default () => {

  const localStorageKey = 'privacy-notification-shown';
  const notificationTranslationKey = 'notification.privacy.text';

  const data = useStaticQuery(graphql`
    query {
      allContentfulTranslation {
        nodes {
          key
          value {
            value
          }
        }
      }
    }
  `);

  const [ isNotificationShown, setNotificationHasBeenShown ] = useState(
    (localStorage && localStorage.getItem(localStorageKey)) || ''
  );

  const onClick = value => {
    setNotificationHasBeenShown(value);
    localStorage && localStorage.setItem(localStorageKey, "true");
  }

  const translate = createTranslate(data.allContentfulTranslation.nodes);

  return (
    <div>
      {!isNotificationShown && translate(notificationTranslationKey) !== notificationTranslationKey
      ? <div
          className="w-full
          flex flex-row flex-no-wrap
          justify-center
          bg-slate-bright shadow
          fixed bottom-0 z-40
          notification-container"
          role="alert"
        >
          <div
            className="block w-11/12
            lg:max-w-1200px
            flex flex-row items-start
            md:pl-6
            py-4"
          >
           <div className="w-9/12"
           >
            <ReactMarkdown
              source={ translate(notificationTranslationKey) || ''}
              renderers={
                {link: props => <a href={props.href} rel="noopener noreferrer" target="_blank">{props.children}</a>}
              }
            />
            </div>
            <button
              className="top-0 bottom-0
                pl-8 py-1"
              onClick={() => { onClick(true) }}
            >
              <img src={closeIcon} alt="Close" className="fill-current w-6" />
            </button>
          </div>
        </div>
      : null}
    </div>
  );
};
