import { useStaticQuery, graphql } from 'gatsby';


/** Search pages itemGroup graphql response for a first matching section
 * @param pageData give the `data.page` graphql
 * @param groupPrefix prefix to search.
 */
export function getPageItemGroup(pageData:any, groupPrefix: string){
    return pageData?.itemGroups?.find(item=> item?.header?.startsWith(groupPrefix) ?? false);
}

export function getPageItemGroupItems(pageData:any, groupPrefix: string){
    return getPageItemGroup(pageData, groupPrefix)?.items ?? [];
}


export function getPageSection(pageData:any, groupPrefix: string){
    return getSection(pageData?.pageSections, groupPrefix)
}

export function getSection(sectionData:any[], groupPrefix: string){
    return sectionData?.find(item=> item?.header?.startsWith(groupPrefix) ?? false);
}




/** TODO Gatsby asset */
export interface BackgroundAsset{
    name: string;
    asset: AssetImage;
    useRepeat: boolean;
    useShadowForText: boolean;
};


export interface AssetImage{
    title?: string;
    localFile: {
        publicURL: string;
        childImageSharp?: {
            fluid?: {
                src: string;
            }
        }
    },
    file: {
        url: string;
    },
    fluid?: {
        src: string;
    }
}


export interface ContactInfo{
    firstName   : string;
    lastName    : string;
    title       : string;
    email       : string;
    phoneNumber? : string;
}


export interface SiteMetadata{
    siteUrl: string;
}

/** Get site static metadata. This data is from gatsby-config.ts */
export const useSiteMetadata = () => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    siteUrl
                }
            }
        }
    `,
    );
    return site.siteMetadata as SiteMetadata;
};