import { useEffect, useMemo, useState } from "react";

export const CONSENT_CATEGORY_Välttämättömät    = "1";
export const CONSENT_CATEGORY_Suorituskyky      = "2";
export const CONSENT_CATEGORY_Toimivuus         = "3";
export const CONSENT_CATEGORY_Kohdistaminen     = "4";
export const CONSENT_CATEGORY_SosiaalisetMediat = "5";

let currentOptanonActiveGroups = null;

/** Check if user has given cookie consent for given category
 * 
 * @param {string|string[]} consentCategories Consent category or categories to check
 * @returns {boolean} Has consent been given
 */
export function useConsent(consentCategories){
    const consentGroups = useOptanonActiveGroups();

    const hasConsent = useMemo(()=>{
        // Assign to local to fix eslint error
        let consentList = consentCategories;

        // Convert param to array if not already
        if(typeof(consentList) === "string")
        consentList = [consentList];

        // Check that all required categories are allowed by the user
        for(const category of consentList){
            if(!consentGroups.includes(category))
                return false;
        }

        // All required categories are found. Allow 
        return true;
    },[consentCategories, consentGroups]);

    return hasConsent;
}

/** Parse Optanon string returning array of active groups */
function parseOptanonActiveGroups(activeGroups){
    // If user has not done any selections, the value is ",,".
    // Else comma separated list of consent categories. Ex. ",1,".
    return activeGroups?.split(",") ?? [];
}


/** hook for OptanonActiveGroups
 * @returns {string[]} Array of enabled consents
 */
function useOptanonActiveGroups(){
    // Initialize with current consent options
    const [activeGroups, setActiveGroups] = useState(()=>{
        const initialData = (typeof window !== "undefined") ? (window as any).OptanonActiveGroups : null;
        return parseOptanonActiveGroups(initialData);
    });

    useEffect(()=>{
        // Key used to track the callback
        const cbKey = Symbol();

        // Add the callback
        OPTANON_CALLBACK_MAP.set(cbKey, setActiveGroups);

        // Clear the callback
        return ()=>{OPTANON_CALLBACK_MAP.delete(cbKey);}
    },[]);

    return activeGroups;
}

/** Map holding callbacks to call when consent selection changes */
const OPTANON_CALLBACK_MAP = new Map();


// Setup magic window function for updating. Only set once
// This file is included in ssr, but window is not available
if(typeof window !== "undefined"){
    // This will be called when consent options change
    (window as any).COOKIE_CONSENT_STARTUP = ()=>{
        const parsedGroups = parseOptanonActiveGroups((window as any).OptanonActiveGroups);
        
        // Go through all callbacks and invoke with new parsed groups
        for(const cb of OPTANON_CALLBACK_MAP.values()){
            cb(parsedGroups);
        }
    };
}



// /** Check if user has given cookie consent for given category
//  * 
//  * @param {string|string[]} consentCategories Consent category or categories to check
//  * @returns {boolean} Has consent been given
//  */
// export function checkConsent(consentCategories){
//     // Setup active groups if this is called before handle change
//     if(currentOptanonActiveGroups == null)
//         currentOptanonActiveGroups = (window as any).OptanonActiveGroups ?? "";

//     console.log(">># checkConsent");
//     if(typeof window === "undefined") return false;

//     // Convert param to array if not already
//     if(typeof(consentCategories) === "string")
//         consentCategories = [consentCategories];

//     // User allowed categoreis are in global 'OptanonActiveGroups' variable.
//     // If user has not done any selections, the value is ",,".
//     // Else comma separated list of consent categories. Ex. ",1,".
//     const consentGroups = (window as any).OptanonActiveGroups?.split(",") ?? [];

//     console.log(">> checkConsent", {consentCategories, consentGroups, OptanonActiveGroups: (window as any).OptanonActiveGroups})

//     // Check that all required categories are allowed by the user
//     for(const category of consentCategories){
//         if(!consentGroups.includes(category))
//             return false;
//     }

//     // All required categories are found. Allow 
//     return true;
// }


// // /** Consent change callback handler */
// function handleConsentChange(){
//     console.log(">># handleConsentChange");
//     if(typeof window === "undefined") return;

//     // If this is first page load, cache active groups
//     if(currentOptanonActiveGroups == null)
//         currentOptanonActiveGroups = (window as any).OptanonActiveGroups;
//     else
//     {
//         // This is not first load. This means that user has changed their preferences
//         // If choises differ, reload page to make sure consent choises are followed
//         if((window as any).OptanonActiveGroups !== currentOptanonActiveGroups)
//             window.location.reload();
//     }
// }

// // // This file is included in ssr, but window is not available
// if(typeof window !== "undefined"){
//     (window as any).MTV_SUOMIAREENA_COOKIE_CONSENT_CHANGE = handleConsentChange;
// }