import React from "react";

/** PageThemeContext type */
export interface PageTheme
{
    isPurple: boolean;
}

/** Sane defaults for PageTheme */
export const DefaultPageTheme: PageTheme = {
    isPurple: false,
}

export const PageThemeContext = React.createContext(DefaultPageTheme);