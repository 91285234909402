import React from 'react';

export default ({ embeddedContent, className, onLoad, onError, style}:any) => {
  return (
    <iframe
      className={className}
      src={embeddedContent.url}
      width="100%"
      height="100%"
      onLoad={onLoad}
      onError={onError}
      style={style}
    >
      {embeddedContent.altText}
    </iframe>
  );
};
