import React from 'react';
import PropTypes from 'prop-types';

export interface HeaderText1Props{
  color?: string;
  className?: string;
  style?: React.CSSProperties;
}

const HeaderText1:React.FC<HeaderText1Props> = ({ color="black", children, className, style }) => {
  const classes:String[] = (className ?? "").split(" ")
  classes.push("font-black")
  classes.push(`text-${color}`)
  const setLeading = classes.filter(x => x.startsWith("leading-"))
  const leading = setLeading.length > 0 ? setLeading[0] : 'leading-normal'
  const classNames = classes.filter(x => !x.startsWith("leading-")).join(" ")

  return (
    <h1
      className={`${classNames} ${leading}`} style={style}
    >
      {children}
    </h1>
  );
};



const HeaderText2 = ({ color, children, className }) => {
  return (
    <h2
      className={`leading-tight text-${color} ${className}`}
      >
      {children}
    </h2>
  );
};

HeaderText2.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

HeaderText2.defaultProps = {
  className: '',
  color: 'black',
};

const HeaderText3 = ({ color, children, className }) => {
  return (
    <h3
      className={`leading-relaxed text-${color} ${className}`}
    >
      {children}
    </h3>
  );
};

HeaderText3.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

HeaderText3.defaultProps = {
  className: '',
  color: 'black',
};

const BodyTextS = ({ color, children, bold, italic, className }) => {
  return (
    <BodyText
      size={'base'}
      color={color}
      bold={bold}
      italic={italic}
      children={children}
      className={className}
    />
  );
};

BodyTextS.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  className: PropTypes.string,
};

BodyTextS.defaultProps = {
  bold: false,
  italic: false,
  className: '',
  color: 'black',
};

const BodyTextM = ({ color, children, bold, italic, className }) => {
  return (
    <BodyText
      size={'lg'}
      color={color}
      bold={bold}
      italic={italic}
      children={children}
      className={className}
    />
  );
};

BodyTextM.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  className: PropTypes.string,
};

BodyTextM.defaultProps = {
  bold: false,
  italic: false,
  className: '',
  color: 'black',
};

const BodyTextL = ({ color, children, bold, italic, className }) => {
  return (
    <BodyText
      size={'2xl'}
      color={color}
      bold={bold}
      italic={italic}
      children={children}
      className={className}
    />
  );
};

BodyTextL.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  bold: PropTypes.bool,
  italic: PropTypes.bool,
  className: PropTypes.string,
};

BodyTextL.defaultProps = {
  bold: false,
  italic: false,
  className: '',
  color: 'black',
};

const BodyText = ({ size, color, children, bold, italic, className }) => {
  let fontFamily = 'font-book';
  if (bold && italic) {
    fontFamily = 'font-semi-bold-italic';
  } else if (bold) {
    fontFamily = 'font-semi-bold';
  } else if (italic) {
    fontFamily = 'font-book-italic';
  }

  return (
    <div
      className={`leading-tight whitespace-pre-line ${fontFamily} text-${size} text-${color} ${className}`}
    >
      {children}
    </div>
  );
};

const InfoText = ({ color, children, className }) => {
  return (
    <p
      className={`font-semi-bold leading-none text-sm uppercase text-${color} ${className}`}
    >
      {children}
    </p>
  );
};

InfoText.propTypes = {
  color: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

InfoText.defaultProps = {
  className: '',
  color: 'black',
};

const TagText = ({ children, className }) => {
  return (
    <p className={`font-semi-bold uppercase text-sm text-grey ${className}`}>
      {children}
    </p>
  );
};

TagText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

TagText.defaultProps = {
  className: '',
};

export {
  HeaderText1,
  HeaderText2,
  HeaderText3,
  BodyTextS,
  BodyTextM,
  BodyTextL,
  InfoText,
  TagText,
};
