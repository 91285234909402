export default translationNodes => {
  const translations = translationNodes
    ? translationNodes.reduce((result, node) => {
        const {
          value: { value },
        } = node;
        result[node.key] = value;
        return result;
      }, {})
    : {};

  return key => {
    return translations[key] || key;
  };
};
