import React from 'react';
import LogoSuomiareenaPNG from "../images/logo-suomiareena.png"

const LogoRed = () => {
  return (
    <Svg>
      <g fill="#FF3334">
        <LogoPathCircle />
        <LogoPathText />
      </g>
    </Svg>
  );
};

const LogoWhite = () => {
  return (
    <Svg>
      <g fill="none" fillRule="evenodd">
        <path fill="#FF3334" d="M0 0H200V200H0z" />
        <g fill="#FFF" fillRule="nonzero">
          <LogoPathCircle />
          <LogoPathText />
        </g>
      </g>
    </Svg>
  );
};

const LogoBlack = () => {
  return (
    <Svg>
      <g>
        <LogoPathCircle />
        <LogoPathText />
      </g>
    </Svg>
  );
};

const LogoFullWhite = ({viewBox = "29 29 141 141"}) => {
  return (
    <Svg viewBox={viewBox}>
      <g fill="#FFF">
        <LogoBackgroundCircle fill="#FFF"/>
        <LogoPathText fill="#1a2732"/>
      </g>
    </Svg>
  );
};

const OwlLogo = () => {
  return (
    <Svg viewBox={'0 0 40 40'}>
      <OwlLogoPath />
    </Svg>
  );
};

const MenuIcon = () => {
  return (
    <Svg viewBox={'0 0 30 30'}>
      <MenuIconPath />
    </Svg>
  );
};

const CloseIcon = () => {
  return (
    <Svg viewBox={'0 0 30 30'}>
      <CloseIconPath color={'#FF3334'} />
    </Svg>
  );
};

const CloseIconWhite = () => {
  return (
    <Svg viewBox={'0 0 30 30'}>
      <CloseIconPath color={'#FFF'} />
    </Svg>
  );
};

const Svg: React.FC<React.SVGProps<SVGSVGElement>> = ({ viewBox, children }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox={viewBox || '0 0 200 200'}
    >
      {children}
    </svg>
  );
};

const LogoBackgroundCircle = ({...props}) => {
  return (
    <circle
      {...props}
      r={69.70} cy={69.70} cx={69.70}
      transform="translate(30.314 30.314)"
    />
  );
};

const LogoPathCircle = () => {
  return (
    <path
      d="M69.67 0C31.253 0 0 31.254 0 69.667s31.254 69.669 69.67 69.669c38.414 0 69.666-31.254 69.666-69.67C139.336 31.252 108.08 0 69.669 0zm0 131.669c-34.19 0-61.996-27.813-61.996-62.002 0-34.19 27.805-61.993 61.995-61.993s62 27.808 62 61.993c0 34.184-27.813 62.002-62 62.002z"
      transform="translate(30.314 30.314)"
    />
  );
};

const LogoPathText = ({...props}) => {
  return (
    <path
      {...props}
      d="M114.43 55.33l-7.873 20.02-6.246-19.22h-10.17l11.78 29.46h8.642s8.246-16.48 8.528-17.107c2.04-4.51-.332-10.6-4.662-13.152zM84.938 79.319c-2.748 0-3.56-2.444-3.56-4.456V63.228h7.058V56.13h-7.058v-8.912h-.614c-4.275 0-8.54 3.328-8.54 8.912v18.233c-.02 1.692.172 3.38.572 5.025 1.287 5.092 6.154 7.29 10.741 7.29 2.994-.003 5.916-.925 8.37-2.64l-2.676-6.269c-.587.406-1.924 1.55-4.293 1.55zM57.707 55.437c-4.032 0-7.56 1.436-9.981 4.085-1.572-2.166-4.065-4.085-8.563-4.085-3.302 0-7.382 2.149-8.85 4.632l-.401-4.617h-.604c-4.062.107-8.083 3.288-8.083 7.763v22.38h9.16V69.75c0-1.883.54-3.433 1.619-4.65 1.08-1.216 2.489-1.824 4.228-1.824 1.565 0 2.74.495 3.525 1.446.785.95 1.176 2.327 1.176 4.087v16.774h8.825V69.741c0-1.883.53-3.433 1.587-4.65 1.058-1.216 2.477-1.824 4.258-1.824 1.565 0 2.743.495 3.525 1.446.783.95 1.179 2.327 1.179 4.087v16.774h9.16V67.377c0-3.684-1.008-6.595-3.021-8.734-2.014-2.139-4.926-3.207-8.739-3.206z"
      transform="translate(30.314 30.314)"
    />
  );
};

const OwlLogoPath = () => {
  return (
    <g>
      <path d="M18.407 21.4h3.137c-.65-.73-1.182-1.558-1.574-2.453-.389.895-.916 1.722-1.563 2.453z" />
      <path d="M19.996 0C8.952 0 0 8.952 0 19.996c0 11.045 8.952 19.998 19.996 19.998 11.045 0 19.997-8.953 19.997-19.997C39.993 8.954 31.04 0 19.996 0zm8.464 24.499c-2.598.003-5.077-1.089-6.83-3.007l-1.646 2.851-1.653-2.861c-2.758 3.042-7.19 3.905-10.89 2.12-3.698-1.785-5.78-5.791-5.116-9.844.665-4.053 3.918-7.184 7.993-7.694 4.075-.51 8 1.724 9.642 5.488 1.84-4.25 6.535-6.488 10.995-5.241 4.46 1.246 7.315 5.594 6.686 10.181-.629 4.588-4.549 8.007-9.18 8.007h-.001z" />
      <path d="M12.883 12.216c-1.844 0-3.34 1.495-3.34 3.339 0 1.844 1.496 3.339 3.34 3.339 1.844 0 3.339-1.495 3.339-3.34 0-1.843-1.495-3.338-3.34-3.338zM27.065 12.215c-1.845 0-3.34 1.494-3.341 3.339 0 1.844 1.494 3.34 3.339 3.34 1.844 0 3.34-1.494 3.34-3.339 0-.885-.35-1.735-.977-2.361-.626-.627-1.476-.98-2.361-.98zM19.983 24.339L18.334 21.481 19.987 24.342 21.633 21.491z" />
    </g>
  );
};

export const LogoSuomiareenaWhite = (props:any)=><img src={LogoSuomiareenaPNG} {...props} />

const MenuIconPath = () => {
  return (
    <g
      fill="none"
      fillRule="evenodd"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1 1)"
    >
      <circle cx="14" cy="14" r="14" strokeWidth="1.2" />
      <path
        strokeWidth="2"
        d="M21 9.824L7 9.824M21 14.024L7 14.024M21 18.224L7 18.224"
      />
    </g>
  );
};

const CloseIconPath = ({color}) => {
  return (
    <g
      fill="none"
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      transform="translate(1 1)"
    >
      <path
        strokeWidth="2"
        d="M17.712 10.287L10.288 17.713M10.288 10.288L17.712 17.712"
      />
      <circle cx="14" cy="14" r="14" strokeWidth="1.2" />
    </g>
  );
};

export {
  LogoRed,
  LogoWhite,
  LogoBlack,
  LogoFullWhite,
  OwlLogo,
  MenuIcon,
  CloseIcon,
  CloseIconWhite,
};
