import React from 'react';
import PropTypes from 'prop-types';

const roundButtonStyle = `w-fit py-3 px-8 rounded-full uppercase bg-red hover:bg-red-dark
  text-white text-sm font-semi-bold leading-none`;

const RoundedButton = ({ onClick, children, className }) => {
  return (
    <Button
      className={`${roundButtonStyle} ${className}`}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      {children}
    </Button>
  );
};

RoundedButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

RoundedButton.defaultProps = {
  onClick: null,
  className: '',
};

const RoundSubmitButton = ({ value, disabled }) => {
  return (
    <input
      disabled={disabled}
      type="submit"
      value={value}
      className={`${roundButtonStyle}
        ${
          disabled
            ? 'bg-grey hover:bg-grey cursor-not-allowed'
            : 'cursor-pointer'
        }`}
    />
  );
};

const TagButton = ({ onClick, children, active, className }) => {
  return (
    <Button
      className={`${
        active
          ? ' bg-red text-white '
          : 'bg-white border-2 border-red text-black '
      } px-8 text-sm font-semi-bold leading-none ${className}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

TagButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
};

TagButton.defaultProps = {
  active: true,
  className: '',
};

const ShowMoreButton = ({ onClick, children, className }) => {
  return (
    <Button
      className={`px-8 text-grey-cool text-sm font-semi-bold leading-none ${className}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

ShowMoreButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

ShowMoreButton.defaultProps = {
  className: '',
};

const Button = ({ className, onClick, children }) => {
  return (
    <button className={className} onClick={e => onClick(e)}>
      {children}
    </button>
  );
};

export { RoundedButton, RoundSubmitButton, TagButton, ShowMoreButton };
