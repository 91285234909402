import React, { useState, useEffect, Children, useContext } from 'react';
import Img from 'gatsby-image';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import Modal from 'react-modal';
import {
  HeaderText1,
  HeaderText2,
  HeaderText3,
  BodyTextL,
  BodyTextM,
  BodyTextS,
} from './text';
import { ButtonLink, NavigationLink, ExternalLink } from './link';
import { PreviewCardWrapper } from './card';
import { CloseIconWhite } from './logo';
import ContactForm from './embedded/contact-form';
import StickyContactForm from './embedded/sticky-contact-form';
import NewAdvertiserContactForm from './embedded/new-advertiser-contact-form';
import EmbeddedObject from './embedded/embedded-object';
import { DividerLine } from './decocration';
import { WHITE, BLACK } from '../utils/color-constants';
import { PAGES_INFO, TEMPLATES_INFO } from '../utils/pages-info';

import '../style/custom.scss';
import { AssetImage, BackgroundAsset, ContactInfo } from '../utils/gatsby-types';
import { CSSProperties } from 'styled-components';
import { PageThemeContext } from '../utils/context-page-theme';
import ExternalVideo from './embedded/external-video';

const getMediaTypeFromAsset = asset => {
  const videoURL =
    asset && asset.file && asset.file.contentType === 'video/mp4'
      ? asset.localFile
        ? asset.localFile.publicURL
        : asset.file.url
      : null;
  const image =
    asset && asset.file && asset.file.contentType !== 'video/mp4'
      ? asset.localFile
        ? asset.localFile.childImageSharp
        : asset
      : null;

  return [videoURL, image];
};

/** Return true if asset is purple (has 'PURPLE' in its name) */
export const IsPurpleAssetImage = (asset: AssetImage)=>!!asset?.title?.match("PURPLE");
/** Return color that should be used for this asset */
export const GetAssetImageAnticolor = (asset: AssetImage)=> IsPurpleAssetImage(asset) ? "bg-primary" : "bg-secondary";

/** Find named asset with name starting with assetPrefix
 * @param assetPrefix begining of the asset name to match
 * @param backgroundAssets assets to find match in
 * @returns
 */

export const findNamedBacgroundAsset = (assetPrefix: string, backgroundAssets:BackgroundAsset[])=>{
  if(backgroundAssets == null) return null;
  return backgroundAssets.find(asset=>asset.name.startsWith(assetPrefix));
}

export const getSectionBackgroundAsset = (sectionName, backgroundAssets) => {
  let assets;
  if(!Array.isArray(backgroundAssets)) {
    return backgroundAssets;
  }
  if (backgroundAssets && backgroundAssets.length === 1) {
    assets = backgroundAssets;
  } else {
    assets = backgroundAssets
      ? backgroundAssets.filter(n => n.name === sectionName)
      : [];
  }

  return assets.length > 0 ? assets[0] : null;
};

const
getCssBackgroundProperty = (asset:BackgroundAsset):CSSProperties => {
  const { asset: fileAsset } = asset || {};

  let bgURL = null;
  if (fileAsset && fileAsset.localFile) {
    bgURL = fileAsset.localFile.publicURL;
  } else if (fileAsset && fileAsset.file) {
    bgURL = fileAsset.file.url;
  }

  if (!bgURL) {
    return {};
  }

  return {
    backgroundImage: `url(${bgURL})`,
  };
};

export const getAssetUrl = (asset: AssetImage) => {
  if(!asset)
    return null;

  if(asset?.localFile?.publicURL)
    return asset.localFile.publicURL;

  if(asset.localFile?.childImageSharp?.fluid?.src)
    return asset.localFile.childImageSharp.fluid.src;

  if(asset?.file?.url)
    return asset.file.url;

  if(asset?.fluid?.src)
    return asset.fluid.src;

  return null;
}

const BackgroundVideo = ({
  videoURL,
  brightness,
  objectPositionVertical,
  objectPositionHorizontal,
}) => {
  return (
    <video
      loop
      autoPlay
      muted
      playsInline
      // name="media"
      className="top-0 left-0 absolute max-h-full min-h-full max-w-full min-w-full object-cover"
      style={{
        filter: `${
          brightness ? `brightness(${brightness}%)` : 'brightness(100%)'
        }`,
        objectPosition: `${
          objectPositionHorizontal ? objectPositionHorizontal : '70'
        }% ${objectPositionVertical ? objectPositionVertical : '20'}%`,
      }}
    >
      <source src={videoURL} type="video/mp4" />
    </video>
  );
};

const BackgroundImage = ({
  image,
  brightness,
  objectPositionVertical,
  objectPositionHorizontal,
}) => {
  return (
    <Img
      className="top-0 left-0 max-h-full min-h-full max-w-full min-w-full object-cover"
      imgStyle={{
        objectPosition: `${
          objectPositionHorizontal ? objectPositionHorizontal : '70'
        }% ${objectPositionVertical ? objectPositionVertical : '20'}%`,
      }}
      style={{
        position: 'absolute',
        filter: `${
          brightness ? `brightness(${brightness}%)` : 'brightness(100%)'
        }`,
      }}
      fluid={image.fluid}
      alt={image.title}
    />
  );
};

const SectionBackground = ({ asset }) => {
  const [videoURL, image] = getMediaTypeFromAsset(asset ? asset.asset : null);
  const brightness = asset && asset.brightness ? asset.brightness : null;
  const objectPositionVertical =
    asset && asset.objectPositionVertical ? asset.objectPositionVertical : null;
  const objectPositionHorizontal =
    asset && asset.objectPositionHorizontal
      ? asset.objectPositionHorizontal
      : null;

  if (videoURL) {
    return (
      <BackgroundVideo
        videoURL={videoURL}
        brightness={brightness}
        objectPositionVertical={objectPositionVertical}
        objectPositionHorizontal={objectPositionHorizontal}
      />
    );
  } else if (image) {
    return (
      <BackgroundImage
        image={image}
        brightness={brightness}
        objectPositionVertical={objectPositionVertical}
        objectPositionHorizontal={objectPositionHorizontal}
      />
    );
  } else {
    return null;
  }
};

const Section = ({ sectionName, backgroundAssets, className, children, id, style }:any) => {
  const asset = findNamedBacgroundAsset(sectionName, backgroundAssets);
  const useRepeat = asset && asset.useRepeat ? asset.useRepeat : null;
  const useShadowForText =
    asset && asset.useShadowForText ? asset.useShadowForText : null;
  return (
    <section
      id={id}
      className={`relative flex flex-row items-center justify-center ${className}`}
      style={Object.assign(style ? style : {} , useRepeat ? getCssBackgroundProperty(asset) : {})}>
      {!useRepeat ? <SectionBackground asset={asset} /> : null}
      <div
        className={`w-11/12 lg:max-w-1200px z-10 ${
          useShadowForText ? 'bg-text-shadow' : ''
        }`}
      >
        {children}
      </div>
    </section>
  );
};

const SectionWithTextAndButton = ({
  backgroundAssets,
  sectionName,
  title,
  body,
  linkTo,
  linkText,
  className,
}:any) => {
  return (
    <SectionWithLeftAlignedText
      backgroundAssets={backgroundAssets}
      sectionName={sectionName}
      title={title}
      body={body}
      linkTo={linkTo}
      linkText={linkText}
      className={className}
    />
  );
};

const SectionWithLeftAlignedText = ({
  backgroundAssets,
  sectionName,
  title,
  body,
  linkTo,
  linkText,
  className,
}: any) => {
  return (
    <Section
      backgroundAssets={backgroundAssets}
      sectionName={sectionName}
      className={className}
    >
      <div className={`w-full max-w-590px`}>
        <HeaderText1 color={WHITE} className={'mb-2 sm:mb-4'}>
          {title}
        </HeaderText1>
        <BodyTextL color={WHITE} className={'mb-4 sm:mb-8'}>
          {body}
        </BodyTextL>
        <ButtonLink to={linkTo}>{linkText}</ButtonLink>
      </div>
    </Section>
  );
};

export interface SimpleButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>{
  /**  test */
  bgClassName?: string,
}

export const SimpleButton: React.FC<SimpleButtonProps> = ({bgClassName, className, children, ...rest})=>{
  const pageTheme = useContext(PageThemeContext);

  // Use bgClassName or theme
  const buttonColor = bgClassName ?? pageTheme.isPurple ? "bg-primary" : "bg-secondary";

  return (
    <button className={`${buttonColor} hover:${buttonColor}-lighter capitalize text-white font-bold py-2 px-4 rounded ${className}`} {...rest}>
      {children}
    </button>
  );
}

export interface FixedBackgroundProps{
  background?: AssetImage;
}

export const FixedBackground: React.FC<FixedBackgroundProps> = ({background})=>{
  let bgCss: CSSProperties = {
    zIndex: -1, // This gets over footer otherwise. Best solution would be to set bg to body element directly
  };

  // If there is background add it
  if(background?.file?.url){
    bgCss = {
      ...bgCss,
      backgroundImage: `url(${background.file.url})`,
      backgroundSize: "cover",
    }
  }

  return <div className="fixed w-full h-full" style={bgCss}></div>
}

export interface TeaserSectionProps{
  style:object;
  backgroundAssets: BackgroundAsset[];
  sectionName: string;
  title: string;
  body: string;
}

export const TeaserSection: React.FC<TeaserSectionProps> = ({ backgroundAssets, sectionName, title, body }) => {
  const logoAsset = findNamedBacgroundAsset("LogoTeaser", backgroundAssets);
  const logoUrl = getAssetUrl(logoAsset?.asset);

  return (
    <Section
      backgroundAssets={backgroundAssets}
      sectionName={sectionName}
      className="w-full py-12 sm:py-32 flex flex-col bg-grey"
      style={{maxHeight:'23.4375rem'}}
    >
      <div style={{maxWidth: "700px"}}>
        {logoUrl && <img src={logoUrl} style={{width:"167px", marginBottom: "16px"}}/>}
        <HeaderText1 color={WHITE} style={{letterSpacing:"0.25pt"}} className="text-30px leading-10 lg:leading-47px lg:text-36px">{title}</HeaderText1>
        {/* <BodyTextL color={WHITE} className={'mt-2 sm:mt-4'}>
          {body}
        </BodyTextL> */}
      </div>
    </Section>
  );
};

export interface BaseContentSectionProps{
  backgroundAsset?: any;
}

/** Simple content section base with background */
export const BaseContentSection: React.FC<BaseContentSectionProps> = ({backgroundAsset, children})=>{
  const sectionStyles: React.CSSProperties = {};

  const bgCss = getCssBackgroundProperty(backgroundAsset);
  bgCss.backgroundSize = "cover";

  return(
    <section id="main-flex-content" className={"relative flex flex-row items-center justify-center"} style={{...bgCss}}>
      <div className={"grid grid-cols-1 py-20 w-11/12 lg:grid-cols-3 lg:gap-20 lg:max-w-1200px"}>
          {children}
      </div>
    </section>
  );
}


export interface PreviewSectionProps{
  imageAsset: AssetImage;
  imageAlt? : string;
  title     : string;
  lead      : string;
  linkUrl   : string;
  linkText  : string;
}
const Image = ({imageAsset, imageAlt}) => imageAsset ? (<div className="order-1 sm:order-none SA-AspectBox16_9">
<img src={getAssetUrl(imageAsset)} alt={imageAlt}/>
</div>
) : null

export const PreviewSection: React.FC<PreviewSectionProps> = ({imageAsset, imageAlt, title, lead, linkUrl, linkText}) =>{
  const showButton = !!linkText

  const rootClass = imageAsset ? "grid grid-cols-1 sm:grid-cols-2 gap-5" : undefined;

  return(
    <div className={rootClass}>
      <Image imageAsset={imageAsset? imageAsset : null} imageAlt={imageAlt} />

      <div className="flex flex-col sm:p-0">
        <h2>{title}</h2>
        <p className="flex-grow py-4 whitespace-pre-wrap">{lead}</p>
        <div className="m-auto sm:m-0">
          {showButton ? <a href={linkUrl}>
            <button className="bg-secondary hover:bg-secondary-lighter uppercase text-white font-bold py-2 px-4 rounded">
              {linkText}
            </button>
          </a> : null}
        </div>
      </div>
    </div>
  )
}


export const PreviewSectionPictureBelowTitle: React.FC<PreviewSectionProps> = ({imageAsset, imageAlt, title, lead, linkUrl, linkText}) =>{
  const showButton = !!linkText
  return(
      <div className="flex flex-col sm:p-0 text-white gap-5">
        <h1>{title}</h1>
        <Image imageAsset={imageAsset? imageAsset : null} imageAlt={imageAlt} />
        <p className="flex-grow py-4">{lead}</p>
        <div className="m-auto sm:m-0">
          {showButton ? <a href={linkUrl}>
            <button className="bg-secondary hover:bg-secondary-lighter uppercase text-white font-bold py-2 px-4 rounded">
              {linkText}
            </button>
          </a> : null}
        </div>
      </div>
  )
}

export interface EventPreviewSectionProps{
  bgAsset : AssetImage;
  iconAsset: AssetImage;

  time    : string;
  location: string;
  city    : string;
  linkUrl : string;
  linkText: string;
}

export const EventPreviewSection: React.FC<EventPreviewSectionProps> = ({bgAsset, iconAsset, time, location, city, linkUrl, linkText})=>{
  const bgImg = getAssetUrl(bgAsset);
  const bgCss = bgImg ? {backgroundImage: `url(${bgImg})`} : undefined;

  const iconSrc = getAssetUrl(iconAsset);

  // See if the bg has 'PURPLE' in its name, and select button color
  const buttonColor = GetAssetImageAnticolor(bgAsset);

  return (
    <div style={bgCss} className="w-full grid grid-cols-12 p-6 bg-cover bg-center">
      <div className="col-span-5 SA-AspectBox1_1">
        <img src={iconSrc} className="w-full h-full object-cover"></img>
      </div>

      <div className="col-span-7 flex flex-col items-end text-right">
        <div><span className="text-lg font-medium leading-6">{time}</span></div>
        <div><p className="text-lg font-medium leading-6">{location}</p></div>
        <div><p className="text-lg font-medium leading-6">{city}</p></div>

        {linkText && linkUrl &&
          <a href={linkUrl} className="mt-auto pt-2 uppercase font-13px">
            <SimpleButton bgClassName={buttonColor}>{linkText}</SimpleButton>
          </a>
        }
      </div>
    </div>
  )
}

export interface ContactsSectionProps{
  contacts: ContactInfo[];
  headerText: string;
}

/** Simple contact section */
export const ContactsSection: React.FC<ContactsSectionProps> = ({contacts, headerText})=>{
  const contactElements = contacts.map((contact, idx)=>{
    return(
      <div key={idx}>
          <div className="font-bold">{contact.title}:</div>
          <div>{contact.firstName} {contact.lastName}</div>
          <div>{contact.email}</div>
          {contact.phoneNumber ? <div>{contact.phoneNumber}</div> : null }
      </div>
    )
  });

  return(
    <div className="flex flex-col gap-5">
      <h1 className="font-bold uppercase text-1.87">{headerText}</h1>

      {contactElements}
    </div>
  )
}


const PricesSection = ({
  backgroundAssets,
  sectionName,
  areaName,
  title,
  body,
  linkText,
  className,
}:any) => {
  return (
    <Section
      backgroundAssets={backgroundAssets}
      sectionName={sectionName}
      className={`py-12 sm:py-32 bg-white ${className}`}
    >
      <div className="flex flex-row justify-center">
        <div className="max-w-590px text-center">
          <HeaderText1 color={BLACK}>{title}</HeaderText1>
          <BodyTextL color={BLACK} className="mt-2 sm:mt-4">
            {body}
          </BodyTextL>
          {/* <ButtonLink
            to={`${PAGES_INFO.campaignCalculator.path}${
              areaName ? `?alue=${areaName}` : ''
            }`}
            className="mt-4 sm:mt-8"
          >
            {linkText}
          </ButtonLink> */}
        </div>
      </div>
    </Section>
  );
};

const CampaignSection = ({
  backgroundAssets,
  title,
  body,
  linkTo,
  linkText,
}) => {
  return (
    <SectionWithLeftAlignedText
      backgroundAssets={backgroundAssets}
      title={title}
      body={body}
      linkTo={linkTo}
      linkText={linkText}
      className={'py-20 sm:py-56 bg-grey'}
    />
  );
};

const MediaInfoSection = ({
  title,
  infoItems,
  pathPrefix,
  keyPrefix,
  useDivider,
}) => {
  return (
    <Section className="pb-12 sm:pb-20">
      {useDivider ? <DividerLine /> : null}
      <HeaderText2 className="mt-12 sm:mt-20">{title}</HeaderText2>
      {infoItems.map( (infoItem, key) => {
        return (
          <MediaInfoSubSection
            key={`${keyPrefix}MediaInfoSubSection_${key}`}
            title={infoItem.title}
            infoItems={infoItem}
            pathPrefix={pathPrefix}
          />
        )
      })}
    </Section>
  );
};

const MediaInfoSubSection = ({
  title,
  infoItems,
  pathPrefix,
  keyPrefix,
}:any) => {
  return (
    <div className="mt-6 sm:mt-12 flex flex-col items-start">
      <HeaderText3>{title}</HeaderText3>
      {infoItems.items.map((item, index) => {
        return(
          <MediaInfoLink
            key={`${keyPrefix}MediaInfoLink_${index}`}
            linkText={item.title}
            file={item.contentFile}
            slug={item.slug}
            pathPrefix={pathPrefix}
            contentNodes={item.content}
          />
        )
      })}
    </div>
  );
};

const MediaInfoLink = ({ linkText, file, slug, pathPrefix, contentNodes }) => {
  const linkStyle = 'mt-5';
  if (file) {
    return (
      <ExternalLink
        to={file.localFile ? file.localFile.publicURL : file.file.url}
        className={linkStyle}
      >
        {linkText}
      </ExternalLink>
    );
  } else if(contentNodes && contentNodes.json) {
    const { json } = contentNodes
    return (
      <Content
        linkStyle={linkStyle}
        contentJson={json}
      />
    )
  }

  return (
    <NavigationLink to={`${pathPrefix}${slug}`} className={linkStyle}>
      {linkText}
    </NavigationLink>
  );
};

const Content = ({ contentJson, linkStyle }:any) => {
  return (
    <div className={`flex flex-col text-left ${linkStyle}`}>
      {documentToReactComponents(contentJson, {
        renderNode: {
          [INLINES.HYPERLINK]: (node, children) => {
            return <a
              target="_blank"
              rel="noopener noreferrer"
              className="font-book text-red leading-loose underline"
              href={node.data.uri}
            >
              {children}
            </a>}
          },
        }
      )}
    </div>
  )
}

const ShowroomSection = ({
  backgroundAssets,
  sectionName,
  title,
  body,
  linkTo,
  linkText,
}) => {
  return (
    <Section
      backgroundAssets={backgroundAssets}
      sectionName={sectionName}
      className="w-full py-12 md:py-32 flex flex-col bg-grey"
    >
      <div className="grid grid-cols-1 md:grid-cols-2">
        <BodyTextL
          color={WHITE}
          bold={true}
          className="max-w-390px md:mr-24 mb-6"
        >
          {title}
        </BodyTextL>
        <div className="max-w-590px">
          <BodyTextM color={WHITE}>{body}</BodyTextM>
          <ButtonLink to={linkTo} className="mt-10">
            {linkText}{' '}
          </ButtonLink>
        </div>
      </div>
    </Section>
  );
};

const ShowRoomExampleSectionWrapper = ({ examples, keyPrefix }) => {
  return (
    <>
      {examples.map((e, i) => {
        return (
          <ShowroomExampleSection
            key={`${keyPrefix}_${i}`}
            example={e}
            className={`${i > 0 ? 'pt-4 sm:pt-12' : 'pt-8 sm:pt-24'}
              ${i < examples.length - 1 ? 'pb-4 sm:pb-12' : 'pb-8 sm:pb-24'}`}
          />
        );
      })}
    </>
  );
};

const ShowroomExampleSection = ({ example, className }) => {
  const [videoURL, image] = getMediaTypeFromAsset(
    example.backgroundAsset ? example.backgroundAsset.asset : null
  );

  let embeddedVideoUrl = null;
  if(example.backgroundAsset) {
    embeddedVideoUrl = example.backgroundAsset.embeddedVideoUrl;
  }

  const brightness =
    example.backgroundAsset && example.backgroundAsset.brightness
      ? example.backgroundAsset.brightness
      : null;

  const useShadowForText =
    example.backgroundAsset && example.backgroundAsset.useShadowForText
      ? example.backgroundAsset.useShadowForText
      : null;

  const anchor = example.title;

  return (
    <Section id={anchor} className={className + " anchor-section"}>
      <div className="grid grid-cols-1 lg:grid-cols-2 row-gap-4">
        <div
          className={`relative flex flex-col justify-center max-h-340px ${
            useShadowForText ? 'bg-text-shadow' : ''
          }`}
        >
          {videoURL ? (
            <video
              controls
              playsInline
              // name="media"
              style={{
                filter: `${
                  brightness ? `brightness(${brightness}%)` : 'brightness(100%)'
                }`,
              }}
            >
              <source src={videoURL} type="video/mp4" />
            </video>
          ) : null}
          {image ? (
            <Img
              fluid={image.fluid}
              style={{
                filter: `${
                  brightness ? `brightness(${brightness}%)` : 'brightness(100%)'
                }`,
              }}
            />
          ) : null}
          {embeddedVideoUrl ? (
            <ExternalVideo
              className="w-full h-81"
              src={embeddedVideoUrl}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen
            />
          ) : null}
          {/** Don't show heading text if this is video */}
          {!(videoURL || embeddedVideoUrl) &&
            <HeaderText2
              color={(videoURL || embeddedVideoUrl) || image ? WHITE : ''}
              className={
                (videoURL || embeddedVideoUrl) || image ? 'item-absolute-center' : 'md:text-center'
              }
            >
              {example.title}
            </HeaderText2>
          }
        </div>
        <div className="lg:px-6 xl:px-12 max-w-590px flex flex-col">
          {example.sellPoints.map((p, i) => {
            return (
              <div key={`sellPoint_${p.title}_${i}`}>
                <HeaderText3 className={`${i === 0 ? 'mt-5' : 'mt-4'}`}>
                  {p.title}
                </HeaderText3>
                <BodyTextS
                  className={`mt-2
                  ${i === example.sellPoints.length - 1 ? 'mb-10' : ''}`}
                >
                  <Content contentJson={p.body.json} />
                </BodyTextS>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

const ShowroomReferencesSection = ({
  backgroundAssets,
  sectionName,
  title,
  body,
  references,
}:any) => {
  return (
    <Section
      backgroundAssets={backgroundAssets}
      sectionName={sectionName}
      className={'py-12 sm:py-32 bg-slate-dark'}
    >
      <div className="max-w-590px">
        <HeaderText2 color={WHITE}>{title}</HeaderText2>
        <BodyTextM color={WHITE} className="mt-4">
          {body}
        </BodyTextM>
      </div>

      <PreviewCardWrapper
        items={references.map(r => {
          return {
            ...r,
            pathPrefix: TEMPLATES_INFO.reference.path,
          };
        })}
      />
    </Section>
  );
};

const ContactSection = ({ formType }:any) => {
  return (
    <BaseContactSection>
      <ContactForm
        formType={formType}
      />
    </BaseContactSection>
  );
};

const BaseContactSection = ({ children }) => {
  return (
    <Section className="py-12 sm:py-20 bg-slate">
      <div className="w-full flex flex-row justify-center">{children}</div>
    </Section>
  );
};

const TemplateContactSection = ({ embeddedContent, className }) => {
  return (
    <Section className="py-12 bg-slate">
      <div className="w-full flex flex-row justify-center">
        <EmbeddedObject
          embeddedContent={embeddedContent}
          className={className}
        />
      </div>
    </Section>
  );
};

const StickyContactSection = ({ openButtonText }) => {
  const [animationStyle, setAnimationStyle] = useState('hidden');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationStyle(
        'fixed-contact-button-animate'
      );
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <section className={`fixed bottom-1 z-40 ${animationStyle}`}>
      <div
        className={`pt-0.5 pb-0 px-6 sm:pt-12 sm:pb-6 sm:px-12 relative flex flex-col bg-slate max-h-screen
          ${open ? 'rounded-lg shadow-2xl' : 'hidden'}`}
      >
        <div className="absolute top-0 right-0">
          <button onClick={() => setOpen(false)} className={`w-10 m-2`}>
            <CloseIconWhite />
          </button>
        </div>
        <StickyContactForm />
      </div>
      <button
        onClick={() => setOpen(true)}
        className={`w-fit py-3 px-8 rounded-full uppercase bg-red hover:bg-red-dark
          shadow-2xl text-white text-sm font-semi-bold leading-none
          ${open ? 'hidden' : ''} `}
      >
        {openButtonText}
      </button>
    </section>
  );
};

const NewAdvertisersGuideContactSection = () => {
  return (
    <Section className="py-12 sm:py-20 bg-slate">
      <div className="w-full flex flex-row justify-center">
        <NewAdvertiserContactForm />
      </div>
    </Section>
  );
};

// Generalized sticky button

// export interface StickyButtonProps{
  // text: string;      // Text on the button
  // offset: number;    // Button slot offset, positive integer
  // delay: number;     // Delay to show button. undefind not used, use show prop
  // show: boolean;     // true to show the button. undefind not used, use delay prop
  // onClick: (e)=>void // Callback when button is pressed
// }
export const StickyButton = ({ text, offset, delay=5000, show, onClick, children }) => {
  const AnimationHidden = 'hidden';
  const AnimationAnimate = 'fixed-contact-button-animate';

  const [animationStyle, setAnimationStyle] = useState('hidden');

  useEffect(() => {
    if(delay == null) return;

    const timer = setTimeout(() => {
      setAnimationStyle(
        'fixed-contact-button-animate'
      );
    }, delay);
    return () => clearTimeout(timer);
  }, []);

  useEffect(()=>{
    if(show == null) return;

    setAnimationStyle(show ? AnimationAnimate : AnimationHidden);
  }, [show]);

  const bottom = `${offset * 3 + 1}rem`;

  return (
    <section className={`fixed z-30 ${animationStyle}`} style={{bottom}}>

      {children}

      <button
        onClick={onClick}
        className={`w-fit py-3 px-8 rounded-full uppercase bg-red hover:bg-red-dark
          shadow-2xl text-white text-sm font-semi-bold leading-none`}
      >
        {text}
      </button>
    </section>
  );
};

// General react-modal popup

// export interface ModalPopupProps{
//   open: Boolean; // Is modal open or not
//   requestClose: (event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>)=>void;
// }
export const ModalPopup = ({open, requestClose, children, ...rest})=>{
  return(
    <>
    <Modal
      isOpen={open}
      onRequestClose={requestClose}
      overlayClassName="ReactModal__Overlay"
      className="ReactModal__Content"
      {...rest}
    >
      {children}
    </Modal>
    </>
  )
}

export {
  Section,
  SectionWithLeftAlignedText,
  PricesSection,
  CampaignSection,
  MediaInfoSection,
  ShowroomSection,
  ShowRoomExampleSectionWrapper,
  ShowroomExampleSection,
  ShowroomReferencesSection,
  SectionWithTextAndButton,
  ContactSection,
  StickyContactSection,
  NewAdvertisersGuideContactSection,
  TemplateContactSection,
};
